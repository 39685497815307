<template>
  <div>
    <v-bottom-navigation color="info" horizontal background-color="#454454" dark>
      <v-btn to="/college/manage_assistant_teacher" class="mr-5">
        <span>รับรายงานตัวครูผู้ช่วย </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/college/assistantcommittee" class="mr-5">
        <span>การแต่งตั้งคณะกรรมการประเมิน</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

      <v-btn to="/college/assistant_teacher" class="mr-5">
        <span>รายงานผลการประเมิน</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      color="info"
      horizontal
      style="background-color: indigo;"
      dark
    >
      <v-btn to="/college/assistantcommitteequalified" class="mr-5">
        <span>เสนอชื่อผู้ทรงคณวุฒิ </span>
        <v-icon>mdi-numeric-1-box-multiple-outline</v-icon>
      </v-btn>

      <v-btn to="/college/assistantcommittee" class="mr-5">
        <span>เสนอชื่อคณะกรรมการประเมิน</span>
        <v-icon>mdi-numeric-2-box-multiple-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "HRvecAssisstantBar",

  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>

<style lang="scss" scoped></style>
